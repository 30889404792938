<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      待付貨款
    </h3>
    <v-row align="center">
      <v-col cols="6" md="3">
        <v-text-field
          label="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" md="3">
        <v-select
          label="店點"
          v-model="filterQuery.branch"
          outlined
          dense
          hide-details
          :items="branchOptions"
          @click:clear="filterQuery.branch = ''"
          clearable
        >
        </v-select>
      </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row v-for="(purchase, purchaseId) of filterData" :key="payload._id">
      <v-col>
        <v-card outlined>
          <v-card-title class="justify-center pb-0">
            {{ purchase[0].branch }}
          </v-card-title>
          <v-card-text class="pa-0 px-2">
            <v-data-table
              :headers="headers"
              :items="purchase"
              :mobile-breakpoint="0"
              hide-default-footer
              :footer-props="{
                itemsPerPageText: '每頁幾筆',
                itemsPerPageOptions: [-1],
              }"
            >
              <template v-slot:item.required="{ item }">
                {{ formatPrice(item.required) }}
              </template>
              <template v-slot:item.cost="{ item }">
                {{ formatPrice(item.cost) }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatPrice(item.amount) }}
              </template>
              <template v-slot:item.paid="{ item }">
                {{ formatPrice(item.paid) }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ item.status === "progress" ? "處理中" : "已完成" }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pb-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="openDialog('pay', purchase)">
              <v-icon class="mr-2">mdi-cash</v-icon>
              付款
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-if="dialog.pay" v-model="dialog.pay" persistent>
      <v-card>
        <v-card-title>應付貨款</v-card-title>
        <v-card-text>
          <v-row
            class="mt-0"
            v-for="(item, n) of payload"
            :key="n"
            align="center"
          >
            <v-col cols="6" align-self="end" class="pb-0 pb-sm-4 text-body-1">
              <v-checkbox
                :input-value="item.paid == item.amount"
                :value="item.paid == item.amount"
                class="mt-0 pt-0"
                dense
                hide-details
                @change="fillAll($event, item)"
              >
                <template v-slot:label>
                  ${{ item.paid }} / ${{ item.amount }} <br />
                  {{ item.product.name }}
                </template>
              </v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-checkbox
                v-model="item.allPaid"
                color="error"
                label="結清"
                class="mt-0 pt-0"
                dense
                hide-details
              >
              </v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model.number="item.currentPaid"
                @input="recalculateSum"
                label="金額"
                type="number"
                dense
                outlined
                hide-details
                clearable
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row cols="12">
            <v-divider class="my-2"></v-divider>
          </v-row>
          <v-row align="end">
            <v-col cols="4">
              <div>總額（自動計算）</div>
              <v-text-field
                :value="total"
                dense
                hide-details
                single-line
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="clear" class="bt-text-large">
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="update(payload)"
            class="bt-text-large"
          >
            送出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import { warehouseUnitOptions } from "@/config/common.js";

export default {
  name: "Payable",
  mixins: [util],
  data: () => ({
    data: [],
    menu: false,
    payload: [],
    dialog: { pay: false },
    filterQuery: {
      branch: "",
      keyword: "",
    },
    total: 0,
    // warehouseUnitOptions: [
    //   { text: "請選擇", value: "", disabled: true },
    //   ...warehouseUnitOptions,
    // ],
    branches: [],
    headers: [
      { text: "商品", value: "product.name", sortable: false },
      { text: "採購量", value: "required", align: "right", sortable: false },
      { text: "單價", value: "cost", align: "right", sortable: false },
      { text: "總金額", value: "amount", align: "right", sortable: false },
      { text: "已付金額", value: "paid", align: "center", sortable: false },
      { text: "狀態", value: "status", align: "center", sortable: false },
      // { text: "操作", value: "actions", align: "center", sortable: false },
      // { text: "備註", value: "comment", align: "center", sortable: false },
    ],
    paid: 0,
  }),
  computed: {
    _: () => _,
    filterData() {
      const filtered = this.data
        .filter((item) => {
          let result = true;
          if (this.filterQuery.branch) {
            result = result && item.branch === this.filterQuery.branch;
          }
          if (this.filterQuery.keyword) {
            result =
              result && item.product.name.includes(this.filterQuery.keyword);
          }
          return result;
        })
        .sort(this.sortDateDesc("createdAt"));
      return _.groupBy(filtered, "purchaseId");
    },
    branchOptions() {
      const branches = this.branches.map((branch) => {
        return { value: branch, text: branch + "店" };
      });
      return branches.length > 1
        ? [{ text: "全部", value: "" }, ...branches]
        : branches;
    },
  },
  async created() {
    // this.resetPayload();
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();

      await this.getPayable();

      this.$vloading.hide();
    },
    async getPayable() {
      if (this.$store.state.user.角色 === "店長") {
        //店長支援友愛，但僅能開放友愛權限
        const { data } = await this.axios.get(`/payable`, {
          params: { branch: "友愛", status: "progress" },
        });
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      } else {
        const { data } = await this.axios.get(`/payable?status=progress`);
        this.data = data;
        this.branches = _.uniqBy(data, "branch").map((item) => item.branch);
      }
    },
    async update(item) {
      const process = [];
      item.forEach((item) => {
        if (item.currentPaid) {
          process.push(
            this.axios.put(`/payable/${item._id}/update-paid`, {
              paid: item.currentPaid,
            })
          );
        }
        if (item.allPaid) {
          process.push(
            this.axios.put(`/payable/${item._id}/update-status`, {
              status: "finished",
            })
          );
        }
      });
      await Promise.all(process);

      this.$toast.success("建立成功");
      this.load();
      this.clear();
    },
    fillAll(val, item) {
      if (val) {
        item.currentPaid = item.amount - item.paid;
        item.allPaid = true;
      } else {
        item.currentPaid = null;
        item.allPaid = false;
      }
      this.recalculateSum();
      this.$forceUpdate();
    },
    recalculateSum() {
      this.total = _.sumBy(this.payload, "currentPaid");
    },
    clear() {
      this.dialog.pay = false;
      // this.resetPayload();
    },
    openDialog(type, payload) {
      if (payload) this.payload = _.cloneDeep(payload);
      // else this.resetPayload();
      this.dialog[type] = true;
    },
    // resetPayload() {},
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
